<template>
  <b-navbar
      type="light"
      class="d-print-none d-flex flex-column align-items-center justify-content-between px-0 pt-3 pb-0 main-navbar"
  >
    <b-navbar-brand
        class="d-flex align-self-start flex-row align-items-start justify-content-start p-0 pl-4 mb-4 py-2"
        to="/"
    >
      <div class="logo-box">
        <!-- the logo comes here -->
        <b-img
            src="@/assets/Asset 8.png"
            class="img-logo px-0"
            height="50px"
            alt="Figa"
        ></b-img>
      </div>
    </b-navbar-brand>
    <b-col
        class="d-flex flex-column justify-content-between align-items-center px-0 w-100"
        is-nav
    >
      <b-navbar-nav
          class="d-flex flex-column align-items-center justify-content-start"
      >
        <template>
          <!-- for testing purpose  -->
          <!-- the exact is used to make sure the roue / is exactly matched -->
          <b-nav-item
              to="/"
              exact
              title="Key Indicators - Income, Expenses, Net Summary, Sales and Payables Summary"
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
              :disabled="!allowedModules.includes('Dashboard') && !allowedModules.includes('All')"
          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-2 py-2"
                no-gutters
            >
              <b-icon
                  icon="house-door"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              />
              <span title=""
                    class="side-nav-text font-primary font-weight-normal text-left"
              >
                Dashboard
              </span>
            </b-row>
          </b-nav-item>
          <b-nav-item
              to="/invoices"
              exact
              title="Create, send, print, record invoices, receipts with different company logos."
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
              :disabled="!allowedModules.includes('Invoicing') && !allowedModules.includes('All')"
          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-2 py-2"
                no-gutters
            >
              <b-icon
                  icon="receipt"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              />
              <span
                  class="side-nav-text font-primary font-weight-normal text-left"
              >
              Invoices
              </span>
              <span
                  class="side-nav-text font-primary font-weight-normal text-left ml-4"
              >
              (Sales & Purchases)
              </span>
            </b-row>
          </b-nav-item>
          <b-nav-item
              to="/quotes"
              exact
              title="Allows quotes to be sent and converted to invoices and reminders."
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
              :disabled="!allowedModules.includes('Quotes') && !allowedModules.includes('All')"
          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-2 py-2"
                no-gutters
            >
              <b-icon
                  icon="file-text"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              />
              <span
                  class="side-nav-text font-primary font-weight-normal text-left"
              >
                Quotes or PO
              </span>
            </b-row>
          </b-nav-item>
          <b-nav-item
              to="/customers"
              title="Customer details, invoice records, outstanding amounts and reminders."
              exact
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
              :disabled="!allowedModules.includes('Customers') && !allowedModules.includes('All')"
          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-2 py-2"
                no-gutters
            >
              <b-icon
                  icon="people"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              />
              <span
                  class="side-nav-text font-primary font-weight-normal text-left"
              >
                Customers
              </span>
            </b-row>
          </b-nav-item>
          <b-nav-item
              to="/supplier"
              title="Allows bank statements to be uploaded and reconciled - individual and bulk."
              exact
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent

          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-2 py-2"
                no-gutters
            >
              <b-icon
                  icon="briefcase"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              />
              <span
                  class="side-nav-text font-primary font-weight-normal text-left"
              >
              Suppliers
              </span>
            </b-row>
          </b-nav-item>
          <b-nav-item
              to="/products"
              title="Inventory items and description with amounts used on invoices."
              exact
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
              :disabled="!allowedModules.includes('Items') && !allowedModules.includes('All')"
          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-2 py-2"
                no-gutters
            >
              <b-icon
                  icon="box"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              />
              <span
                  class="side-nav-text font-primary font-weight-normal text-left"
              >
                Item Codes
              </span>
            </b-row>
          </b-nav-item>
          <b-nav-item
              to="/transactions"
              title="Allows bank statements to be uploaded and reconciled - individual and bulk."
              exact
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
              :disabled="!allowedModules.includes('Transactions with Bank') && !allowedModules.includes('All')"
          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-2 py-2"
                no-gutters
            >
              <b-icon
                  icon="cash-stack"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              />
              <span
                  class="side-nav-text font-primary font-weight-normal text-left"
              >
              Bank Reconciliations
              </span>
            </b-row>
          </b-nav-item>
          <!--          <b-nav-item-->
          <!--              to="/manualJournals"-->
          <!--              title="Allows bank statements to be uploaded and reconciled - individual and bulk."-->
          <!--              exact-->
          <!--              class="side-nav-item my-2"-->
          <!--              link-classes="p-0"-->
          <!--              active-class="active-link"-->
          <!--              @click.prevent-->
          <!--              :disabled="!allowedModules.includes('ManualJournals')"-->
          <!--          >-->
          <!--            <b-row-->
          <!--                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-2"-->
          <!--                no-gutters-->
          <!--            >-->
          <!--              <b-icon-->
          <!--                  icon="file-text"-->
          <!--                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"-->
          <!--              />-->
          <!--              <span-->
          <!--                  class="side-nav-text font-primary font-weight-normal text-left"-->
          <!--              >-->
          <!--              Manual Journals-->
          <!--              </span>-->
          <!--            </b-row>-->
          <!--          </b-nav-item>-->
          <b-nav-item
              to="/charts"
              title="Standard chart of accounts, creation and deletion of GL codes."
              exact
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
              :disabled="!allowedModules.includes('CoA') && !allowedModules.includes('All')"
          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-2 py-2"
                no-gutters
            >
              <b-icon
                  icon="file-earmark-spreadsheet"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              />
              <span
                  class="side-nav-text font-primary font-weight-normal text-left"
              >
                Chart of Accounts
              </span>
            </b-row>
          </b-nav-item>
          <!--          <b-nav-item-->
          <!--            title="Sales"-->
          <!--            class="side-nav-item my-2"-->
          <!--            link-classes="p-0"-->
          <!--            active-class="active-link"-->
          <!--            v-b-toggle.collapse-a.collapse-b.collapse-c-->
          <!--          >-->
          <!--            <b-row-->
          <!--              class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-1"-->
          <!--              no-gutters-->
          <!--            >-->
          <!--              <div class="d-flex flex-row">-->
          <!--                <b-icon-->
          <!--                  icon="newspaper"-->
          <!--                  class="jusify-content-center align-items-center side-nav-icon pr-2"-->
          <!--                />-->
          <!--                <span-->
          <!--                  class="side-nav-text font-primary font-weight-normal text-left"-->
          <!--                >-->
          <!--                  Sales-->
          <!--                </span>-->
          <!--              </div>-->
          <!--              <div class="ml-auto p-1">-->
          <!--                <b-icon icon="chevron-down" class="pr-2 side-nav-text" />-->
          <!--              </div>-->
          <!--            </b-row>-->
          <!--            <b-collapse id="collapse-a" class="mt-2">-->
          <!--              <b-nav-item-->
          <!--                to="/invoices"-->
          <!--                title="Invoices"-->
          <!--                class="side-nav-subitem"-->
          <!--                @click.prevent-->
          <!--              >-->
          <!--                <b-row-->
          <!--                  class="d-flex flex-row align-items-center justify-content-start mx-auto"-->
          <!--                  no-gutters-->
          <!--                >-->
          <!--                  <span-->
          <!--                    class="side-nav-text font-primary font-weight-normal text-left"-->
          <!--                  >-->
          <!--                    Invoices-->
          <!--                  </span>-->
          <!--                </b-row>-->
          <!--              </b-nav-item>-->
          <!--            </b-collapse>-->
          <!--            <b-collapse id="collapse-b" class="mt-2">-->
          <!--              <b-nav-item-->
          <!--                  to="/quotes"-->
          <!--                  title="Invoices"-->
          <!--                  class="side-nav-subitem"-->
          <!--                  @click.prevent-->
          <!--              >-->
          <!--                <b-row-->
          <!--                    class="d-flex flex-row align-items-center justify-content-start mx-auto"-->
          <!--                    no-gutters-->
          <!--                >-->
          <!--                  <span-->
          <!--                      class="side-nav-text font-primary font-weight-normal text-left"-->
          <!--                  >-->
          <!--                    Quotes-->
          <!--                  </span>-->
          <!--                </b-row>-->
          <!--              </b-nav-item>-->
          <!--            </b-collapse>-->
          <!--            <b-collapse id="collapse-b">-->
          <!--              <b-nav-item-->
          <!--                to="/customers"-->
          <!--                title="Customers"-->
          <!--                class="side-nav-subitem"-->
          <!--                @click.prevent-->
          <!--              >-->
          <!--                <b-row-->
          <!--                  class="d-flex flex-row align-items-center justify-content-start mx-auto"-->
          <!--                  no-gutters-->
          <!--                >-->
          <!--                  <span-->
          <!--                    class="side-nav-text font-primary font-weight-normal text-left"-->
          <!--                  >-->
          <!--                    Customers-->
          <!--                  </span>-->
          <!--                </b-row>-->
          <!--              </b-nav-item>-->
          <!--            </b-collapse>-->
          <!--            <b-collapse id="collapse-c">-->
          <!--              <b-nav-item-->
          <!--                to="/products"-->
          <!--                title="Products & Services"-->
          <!--                class="side-nav-subitem"-->
          <!--                @click.prevent-->
          <!--              >-->
          <!--                <b-row-->
          <!--                  class="d-flex flex-row align-items-center justify-content-start mx-auto"-->
          <!--                  no-gutters-->
          <!--                >-->
          <!--                  <span-->
          <!--                    class="side-nav-text font-primary font-weight-normal text-left"-->
          <!--                  >-->
          <!--                    Products & Services-->
          <!--                  </span>-->
          <!--                </b-row>-->
          <!--              </b-nav-item>-->
          <!--            </b-collapse>-->
          <!--          </b-nav-item>-->
          <!--          <b-nav-item-->
          <!--            title="Accounting"-->
          <!--            class="side-nav-item my-2"-->
          <!--            link-classes="p-0"-->
          <!--            active-class="active-link"-->
          <!--            v-b-toggle.collapse-d.collapse-e-->
          <!--          >-->
          <!--            <b-row-->
          <!--              class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-3 py-1"-->
          <!--              no-gutters-->
          <!--            >-->
          <!--              <div class="d-flex flex-row">-->
          <!--                <b-icon-->
          <!--                  icon="cash"-->
          <!--                  class="jusify-content-center align-items-center side-nav-icon pr-2"-->
          <!--                />-->
          <!--                <span-->
          <!--                  class="side-nav-text font-primary font-weight-normal text-left"-->
          <!--                >-->
          <!--                  Accounting-->
          <!--                </span>-->
          <!--              </div>-->
          <!--              <div class="ml-auto p-1">-->
          <!--                <b-icon icon="chevron-down" class="pr-2 side-nav-text" />-->
          <!--              </div>-->
          <!--            </b-row>-->
          <!--            <b-collapse id="collapse-d" class="mt-2">-->
          <!--              <b-nav-item-->
          <!--                to="/transactions"-->
          <!--                title="Transactions"-->
          <!--                class="side-nav-subitem"-->
          <!--                @click.prevent-->
          <!--              >-->
          <!--                <b-row-->
          <!--                  class="d-flex flex-row align-items-center justify-content-start mx-auto"-->
          <!--                  no-gutters-->
          <!--                >-->
          <!--                  <span-->
          <!--                    class="side-nav-text font-primary font-weight-normal text-left"-->
          <!--                  >-->
          <!--                    Transactions-->
          <!--                  </span>-->
          <!--                </b-row>-->
          <!--              </b-nav-item>-->
          <!--            </b-collapse>-->
          <!--            <b-collapse id="collapse-e">-->
          <!--              <b-nav-item-->
          <!--                to="/charts"-->
          <!--                title="Chart of Accounts"-->
          <!--                class="side-nav-subitem"-->
          <!--                @click.prevent-->
          <!--              >-->
          <!--                <b-row-->
          <!--                  class="d-flex flex-row align-items-center justify-content-start mx-auto"-->
          <!--                >-->
          <!--                  <span-->
          <!--                    class="side-nav-text font-primary font-weight-normal text-left"-->
          <!--                  >-->
          <!--                    Chart of Accounts-->
          <!--                  </span>-->
          <!--                </b-row>-->
          <!--              </b-nav-item>-->
          <!--            </b-collapse>-->
          <!--          </b-nav-item>-->
          <b-nav-item
              to="/reports"
              title="Standard Reports - Profit & Loss, Balance Sheet, Trial Balance, Cashflow, Financial Report and GST Report."
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-2 py-2"
                no-gutters
            >
              <b-icon
                  icon="bar-chart-line"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              />
              <span
                  class="side-nav-text font-primary font-weight-normal text-left"
              >
                Reports
              </span>
            </b-row>
          </b-nav-item>
          <b-nav-item
              to="/assets"
              title=""
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
              :hidden="!allowedModules.includes('FixedAssets')"
          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-2 py-2"
                no-gutters
            >
              <b-icon
                  icon="book"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              />
              <span
                  class="side-nav-text font-primary font-weight-normal text-left"
              >
              Fixed Assets
              </span>
            </b-row>
          </b-nav-item>
          <b-nav-item
          v-if="allowedModules.includes('ManualJournals')"
              to="/manualJournals"
              title="Allows bank statements to be uploaded and reconciled - individual and bulk."
              exact
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-2 py-2"
                no-gutters
            >
              <b-icon
                  icon="cash-stack"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              />
              <span
                  class="side-nav-text font-primary font-weight-normal text-left"
              >
              Manual Journal
              </span>
            </b-row>
          </b-nav-item>
        </template>
      </b-navbar-nav>
      <b-navbar-nav
          class="d-flex flex-column align-items-center justify-content-start"
      >
        <template v-for="(item, index) in menuArrayBottom">
          <!-- :active="sideMenuIndex == index + 1 + menuArrayTop.length ? true : false" -->
          <b-nav-item
              :key="key + index"
              :to="item.route"
              :title="item.title"
              @click="routeFunc(item, index + 1 + menuArrayTop.length)"
              class="side-nav-item my-2"
              link-classes="p-0"
              active-class="active-link"
              @click.prevent
              :hidden="item.isDisabled"
          >
            <b-row
                class="d-flex flex-row align-items-center justify-content-start side-nav-item-row px-2 py-2"
                no-gutters
            >
              <b-icon
                  font-scale="2"
                  :icon="item.icon"
                  class="d-flex jusify-content-center align-items-center side-nav-icon pr-2"
              ></b-icon>
              <span
                  class="side-nav-text font-primary font-weight-normal text-left" v-bind:title="item.title"
              >{{ item.label }}</span
              >
            </b-row>
          </b-nav-item>
        </template>
      </b-navbar-nav>
    </b-col>
  </b-navbar>
</template>
<script>
/* eslint-disable no-console */
// import { mapGetters } from "vuex";
import {mapGetters} from "vuex";

export default {
  name: "NavBar",
  // props: {
  //   sideMenuExpanded: {
  //     default: false,
  //     type: Boolean,
  //   },
  // },
  data() {
    return {
      role:null,
      key: Number(new Date()),
      menuArrayTop: [],
      menuArrayBottom: [],
      isMultiple: false,
      allowedModules: ''
    };
  },

  // computed: {
  //   ...mapGetters({
  //     sideMenuIndex: "getSideMenuIndex",
  //   }),
  // },
  created() {
    // this.role = role
    this.menuArrayTop = [
      {
        icon: "house-door",
        label: "Dashboard",
        route: "",
      },
      {
        icon: "newspaper",
        label: "Sales",
        route: "/sales",
      },
      {
        icon: "cash",
        label: "Accounting",
        route: "/accounting",
      },
      {
        icon: "bar-chart-line",
        label: "Reports",
        route: "/reports",
      },
    ];
    // this.menuArrayTop = [
    //   {
    //     icon: "house-door",
    //     label: "Dashboard",
    //     route: "",
    //   },
    //   {
    //     icon: "newspaper",
    //     label: "Sales",
    //     route: "/sales",
    //     isMultiple: true,
    //     content: [
    //       {
    //         label: "Invoices",
    //         route: "/invoices",
    //       },
    //       {
    //         label: "Customers",
    //         route: "/customers",
    //       },
    //     ],
    //   },
    //   {
    //     icon: "cash",
    //     label: "Accounting",
    //     route: "/accounting",
    //     isMultiple: true,
    //     content: [
    //       {
    //         label: "Transactions",
    //         route: "/transactions",
    //       },
    //       {
    //         label: "Chart Of Accounts",
    //         route: "/chartofaccounts",
    //       },
    //     ],
    //   },
    //   {
    //     icon: "bar-chart-line",
    //     label: "Reports",
    //     route: "/reports",
    //   },
    // ];
    this.menuArrayBottom = [
      {
        active: false,
        icon: "folder",
        label: "Workpaper Folder",
        route: "/workpaperFolder",
        title: "",
        isDisabled: !this.allowedModules.includes('WorkpaperFolder')
      },
      {
        active: false,
        icon: "gear",
        label: "Settings",
        route: "/settings",
        title: "Allows for trial balance migration from other systems, configure invoice templates and manage user permissions and invites.",
        isDisabled: false
      },
      {
        active: false,
        icon: "box-arrow-in-right",
        label: "Sign Out",
        route: "/login",
        title: "",
        isDisabled: false
      },
    ];
    // if (localStorage.getItem("masterAdmin")) {
    //   let admin = JSON.parse(localStorage.getItem("masterAdmin"));
    //   this.adminType = admin.adminType;
    // }
  },
  mounted() {
 
    this.allowedModules = JSON.parse(this.getModulePermissions())[0].modules;
    this.menuArrayBottom[0].isDisabled = !this.allowedModules.includes('WorkpaperFolder');
  },

  // watch: {
  //   isShow(value) {
  //     this.showSidebar = value;
  //   },
  // },
  methods: {
    ...mapGetters({getModulePermissions: "auth/getModulePermissions",getRole:'auth/getUserRole'}),
    // toggleCollapse() {
    //   let showSideMenu = !this.sideMenuExpanded;
    //   this.$store.dispatch("setIsSideMenuExpanded", showSideMenu);
    // },
    logout() {
      this.$store.dispatch("setSideMenuIndex", 0);
      localStorage.clear();
      this.$store.dispatch("setIsLoading", false);
      this.$router.replace("/login").catch((err) => {
        console.log("LOGOUT ERROR: ", err);
      });
      // location.reload();
    },
    routeFunc(item, index) {
      console.log("route item", item);
      this.key = Number(new Date());
      this.$store.dispatch("setIsLoading", false);
      // this.$store.dispatch("setSideMenuIndexKey", this.key);
      this.$store.dispatch("setSideMenuIndex", index);
      if (item.label === "Sign Out") {
        this.logout();
      } else {
        this.$router.replace(item.route).catch((err) => {
          console.log("ROUTE SWITCH ERROR: ", err);
        });
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-navbar {
  /* background-color: var(--primary); */
  background-color: #FFE400;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.05);
  position: fixed;
  width: 200px;
  max-width: 200px;
  /* height: calc(100% - 60px); */
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  min-height: 100vh;
  /* min-height: calc(100vh - 140px); */
  z-index: 200;
  top: 0;
  bottom: 0;
  left: 0;
  transition: max-width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.main-navbar::-webkit-scrollbar {
  width: 0.5rem;
  scrollbar-width: thin;
}

.main-navbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.main-navbar::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

/* side nav design */
.navbar .navbar-nav {
  width: 90%;
}

/* side nav design */
.navbar .navbar-nav .side-nav-item {
  width: 100%;
}

/* side nav logo box */
.logo-box {
  /* box-sizing: border-box; */
  position: relative;
  width: 150px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background: rgba(255, 255, 255, 0.25); */
  /* background: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12.5px); */
  border-radius: 5px;
}

/* nav link (icon/text) color (default state)  */
.side-nav-item a.nav-link .side-nav-item-row {
  width: 100%;
  border-radius: 0.25rem;
  background-color: transparent;
  padding: 0.5rem 1.25rem;
  transition: background-color 100ms linear 0s, color 100ms linear 0s;
}

.side-nav-item a.nav-link .side-nav-item-row .side-nav-icon {
  /* color: var(--black); */
  color: black;
  transition: color 100ms ease-in;
  user-select: none;
  height: 26px;
  width: 26px;
}

.side-nav-item a.nav-link .side-nav-item-row .side-nav-text {
  font-family: "Lato";
  font-size: 12px;
  /* color: var(--black); */
  color: black;
  padding-left: 0.65rem;
  transition: color 100ms ease-in, opacity 100ms cubic-bezier(0.2, 0, 0, 1) 0s;
  opacity: 1;
  user-select: none;
}

/* nav link (icon/text) hover color (default state) */
.side-nav-item a.nav-link:hover .side-nav-item-row {
  background-color: var(--light);
}

.side-nav-item a.nav-link:hover .side-nav-item-row .side-nav-icon,
.side-nav-item a.nav-link:hover .side-nav-item-row .side-nav-text {
  color: black;
}

/* nav link (icon/text) color (active state)  */
.side-nav-item a.nav-link.active-link .side-nav-item-row {
  cursor: auto;
  background-color: var(--light);
}

.side-nav-item a.nav-link.active-link .side-nav-item-row .side-nav-icon {
  color: black;
}

.side-nav-item a.nav-link.active-link .side-nav-item-row .side-nav-text {
  color: black;
  font-family: "Lato";
  font-size: 12px;
}

/* sub item styling */
.side-nav-item .side-nav-subitem {
  width: 100%;
  max-width: 80%;
  margin-left: 20%;
  color: var(--white);
}

/* nav sub item default state */
.side-nav-item .side-nav-subitem .side-nav-text {
  color: black;
}

/* nav sub item hover state */
.side-nav-item .side-nav-subitem .side-nav-text:hover {
  color: black;
}

.side-nav-item a.nav-link.active-link .side-nav-subitem .side-nav-text {
  color: black;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .main-navbar.collapsed {
    max-width: 60px;
  }
}
</style>
