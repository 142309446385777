import axios from "axios";

export const GetCurrentWorkspace = async () => {
  return await axios.get("/auth/myBusiness");
};

export const GetSingleWorkspace = async (payload) => {
  try {
    return await axios.get(`/business/?id=${payload}`);
  } catch (error) {
    return error;
  }
};

// update workspace by owner
export const UpdateSingleWorkspace = async (payload) => {
  try {
    return await axios.put(`/business/${payload.id}`, payload);
  } catch (error) {
    return error;
  }
};

export const createBusiness = async (payload) => {
  try {
    return await axios.post(`/register/business`, payload);
  } catch (error) {
    return error;
  }
};

export const UpdateCurrentWorkspace = async (payload) => {
  try {
    return await axios.put(`/workspace/update/`, payload);
  } catch (error) {
    return error;
  }
};

export const GetInvoiceDefaults = async () => {
  try {
    return await axios.get("/invoice_defaults");
  } catch (error) {
    return error;
  }
};

export const UpdateInvoiceDefaults = async (payload) => {
  try {
    return await axios.post(`/invoice_defaults`, payload);
  } catch (error) {
    return error;
  }
};

export const BulkUploadTransactions = async (payload) => {
  return await axios.post(`/import/waves/transactions`, payload);
};

export const UploadLogo = async (payload) => {
  try {
    return await axios.post(`/workspace/logos/`, payload);
  } catch (error) {
    return error;
  }
};

export const GetWorkspaceMembers = () => {
  return axios.get(`/workspace/members/`);
};

export const GetPackageDetails = () => {
  return axios.get(`/packages/get_package/`);
};

export const GetPackageTrailDays = async () => {
  try {
    return await axios.get("/packages/days_to_end_trial");
  } catch (error) {
    return error;
  }
};
