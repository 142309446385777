<template>
  <b-container class="nav-footer footer w-100 align-self-center d-print-none">
    <h5 class="version-number mb-0 mx-0 text-muted text-center w-100">
     {{ appVersion }}
    </h5>
  </b-container>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      appVersion:process.env.VUE_APP_VERSION 
    }
  },
};
</script>

<style scoped>
.nav-footer {
  position: fixed;
  /* left: 0; */
  bottom: 0;
  width: 100%;
  height: 20px;
}
.version-number {
  /* color: var(--gray); */
  font-size: 0.7rem;
  line-height: 20px;
}
</style>
