<template>
  <b-navbar
    type="light"
    class="d-print-none d-flex flex-row justify-content-start align-items-center px-2 px-sm-3 px-md-6 top-navbar"
    :class="isShowSideMenu ? 'top-navbar collapsed' : 'top-navbar'"
  >
    <b-row
      class="d-flex flex-row align-items-center justify-content-start mx-0 mr-auto" 
    >
      <h1>{{ count }}</h1>
      <!-- mobile side menu button (only visible on mobile) -->
      <b-col class="d-flex  align-items-center" cols="2" >
      <b-button
        class="d-block d-md-none border border-0 px-2 py-1 mr-2"
        variant="transparent"
        @click="showSideMenu"
      >
        <b-icon icon="menu-button" size="lg" aria-label="Menu">Hello</b-icon>
      </b-button>
      </b-col>
      <!-- title -->
      <b-col class="d-flex align-items-center col-10 col-lg-12" >
      <template v-if="subPage">
        <h5
          class="font-primary display-text text-main-green text text-left mb-0"
        >
          {{ parentPage }}
        </h5>
        <b-icon
          icon="chevron-right"
          aria-label="SubMenu"
          class="mx-2 mt-1"
        ></b-icon>
        <h5 class="font-primary display-text text-main-green text-left mb-0">
          {{ subPage }}
        </h5>
      </template>
      <template v-else>
        <h5 class="font-primary display-text text-main-green text-left mb-0">
          {{ title }}
        </h5>
       <div v-if="title != 'Support Center' ">
          <b-icon
               
               id="info-icon-signup"
               icon="info-circle-fill" 
               size="sm"
               class="no-fill-icon "
               style="outline: none;"
           ></b-icon>
        <b-tooltip target="info-icon-signup" placement="bottomright" variant="white" custom-class="SupportTooltip" >
          <div  class="custom-tooltip-content " >
                 To learn more about {{toolTipTitle}}.  <router-link :to="{ path: '/supportCenter', query: { title: videoTitle.replace(/\s+/g, '') } }"  class="tooltip-link text-main-green">
        &nbsp; click here &gt;&gt;
      </router-link>
               </div>
                </b-tooltip>
            
              </div>        
      </template>
    </b-col>
    </b-row>
    <a href="/supportCenter" style="text-decoration: none;">
          <h5 class="font-primary supportCenter-text text-main-green text-left mb-0">
         Support Center
        </h5>
        </a>
    <!-- user account icon -->
    <b-dropdown
      variant="white"
      class="user-icon-button ml-0 ml-sm-2"
      toggle-class="d-flex flex-row align-items-center justify-content-between border-0 px-2 py-2 p-0 text-decoration-none user-icon-pill"
      menu-class="w-100 bg-white rounded-lg user-icon-menu"
      right
      no-caret
    >
      <template #button-content>
        <!-- TODO: get the text avatar from user name -->
        <!-- :text="adminDetails ? firstLetterFn(adminDetails.firstName) : ''" -->
        <b-avatar
          class="mr-2 user-avatar bg-light text-dark font-weight-bold border border-dark"
          size="34px"
        ></b-avatar>
        <template v-if="isBusinessLoading">
          <b-overlay
            :show="isBusinessLoading"
            no-wrap
            variant="white"
            spinner-small
          ></b-overlay>
        </template>
        <template v-else>
          <span
            class="mr-3 d-block text-left text-medium text-dark font-secondary font-weight-bold text-truncate mb-1"
          >
            <!-- Finance -->
            {{ worksPaceName ? worksPaceName : "" }}
          </span>
        </template>
        <b-icon
          icon="chevron-right"
          class="mb-1"
          aria-label="View Menu"
          size="sm"
          font-scale="0.9"
        ></b-icon>
      </template>
      <b-dropdown-group>
        <!-- current user details -->
        <!-- <b-dropdown-text
          class="w-100"
          text-class="text-medium text-primary font-weight-normal px-2 py-2"
        >
          <b-col cols="12" class="px-0 align-items-start">
            <b-img
              src="@/assets/TestLogo_navbar.svg"
              class="img-logo px-1 w-100"
              height="5px"
              alt="Finance"
            ></b-img>
            Your Finance Accounts
          </b-col>
        </b-dropdown-text> -->
        <b-row
          class="d-flex flex-row align-items-center justify-content-start px-4"
          no-gutters
        >
          <!-- <b-col cols="12" class="px-0 align-items-start"> -->
          <!-- the logo comes here -->
          <!-- <b-img
              src="@/assets/only_logo.svg"
              class="img-logo px-1"
              height="45px"
              alt="Finance Logo"
            ></b-img> -->
          <span
            class="d-block text-left text-medium text-main-green font-weight-bold text-truncate my-2"
          >
            Your Finance Accounts
          </span>
          <!-- </b-col> -->
        </b-row>
        <!-- current user details -->
        <b-dropdown-text class="w-100" text-class="px-4">
          <b-col cols="12" class="px-0 align-items-start">
            <!-- user name -->
            <template v-if="userProfile">
              <b-link
                class="d-block text-left text-medium font-secondary font-weight-bold bg-light px-1 py-1 text-truncate mb-2 workspace-btn"
                v-for="(business, index) in userProfile.member_of"
                :key="index"
                @click="switchWorkspaceFn(business.business_id)"
                >{{
                  business.business_name
                    ? business.business_name
                    : "No Business"
                }}
              </b-link>
            </template>
            <!-- <span
              class="d-block text-left text-medium text-dark font-secondary font-weight-bold text-truncate mb-1"
            >
              {{ this.getCurrentWorkspace.name }}
              {{ adminDetails ? adminDetails.firstName : "" }}
            </span> -->
            <!-- user type -->
            <!-- <span
              class="d-block text-left text-prime-gray text-small font-secondary"
            >
              Owner
              {{ adminDetails ? adminDetails.adminType : "" }}
            </span> -->
          </b-col>
        </b-dropdown-text>
        <b-dropdown-item-button
          variant="main-green"
          button-class="px-4 pt-1"
          @click="businessCreateFn"
        >
          <!-- <b-avatar
          class="user-avatar bg-light text-dark font-weight-bold border border-dark"
          size="20px"
        ></b-avatar> -->
          <b-icon
            icon="plus-circle-fill"
            class="pt-1 text-main-green"
            scale="1.1"
            aria-hidden="true"
          ></b-icon>
          <span class="text-left text-small text-main-green pl-2"
            >Create a New Business</span
          >
          <b-icon
            v-if="activePackage === 'Figa Green'"
            icon="lock-fill"
            class=""
            scale="1.1"
            aria-hidden="true"
            variant="warning"
            title="This feature is not available in your package"
          ></b-icon>
        </b-dropdown-item-button>
        <!-- <b-dropdown-divider></b-dropdown-divider> -->
        <hr style="width: 92%; text-align: start; margin-top: 1em" />
        <!-- full row Statement date -->
        <!-- <b-row
          class="d-flex flex-row align-items-start justify-content-end w-100 px-4 pb-3"
          no-gutters
        > -->
        <b-dropdown-text class="w-100" text-class="px-4 mb-1">
          <span
            class="text-left text-gray-750 font-primary font-weight-normal state-text"
          >
            Signed in as
            <span class="d-inline text-dark font-weight-bold">{{
              useEmail ? useEmail : ""
            }}</span>
          </span>
        </b-dropdown-text>
        <!-- </b-row> -->
        <b-dropdown-item-button
          variant="main-green"
          button-class="px-4 pt-1"
          @click="settingsFn"
        >
          <!-- <b-avatar
          class="user-avatar bg-light text-dark font-weight-bold border border-dark"
          size="20px"
        ></b-avatar> -->
          <b-icon
            icon="person-circle"
            class="pt-1 text-main-green"
            scale="1.1"
            aria-hidden="true"
          ></b-icon>
          <span class="text-left text-small text-main-green pl-2"
            >Manage Your Profile</span
          >
          <b-icon
            v-if="
              !allowedModules.includes('Profile') &&
              !allowedModules.includes('All')
            "
            icon="lock-fill"
            class=""
            scale="1.1"
            aria-hidden="true"
            variant="warning"
            title="This feature is not available in your package"
          ></b-icon>
        </b-dropdown-item-button>
        <b-dropdown-item-button button-class="px-4 pb-1" @click="logoutFn">
          <b-icon
            icon="box-arrow-in-right"
            class="pt-1 text-main-green"
            scale="1.1"
            aria-hidden="true"
          ></b-icon>
          <span class="text-left text-small text-main-green pl-2"
            >Sign Out</span
          >
        </b-dropdown-item-button>
        <hr style="width: 92%; text-align: start; margin-top: 1em" />
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100 px-4 pb-2"
          no-gutters
        >
          <b-link
            class="text-left font-primary text-gray-750 font-weight-normal mr-3 terms-text"
            >Terms
          </b-link>
          <b-link
            class="text-left font-primary text-gray-750 font-weight-normal ml-2 terms-text"
            >Privacy
          </b-link>
        </b-row>
      </b-dropdown-group>
    </b-dropdown>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// services
import { GetUserProfile } from "@/services/settings.service";
import { GetCurrentWorkspace } from "@/services/settingsbusiness.service";

export default {
  name: "TopBar",
  data() {
    return {
      // useEmail: null,
      userProfile: null,
      currentBusiness: {},
      isBusinessLoading: false,
      allowedModules: "",
      activePackage: "",
  
    };
  },
  props: {
    isShowSideMenu: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      // getCurrentWorkspace: "auth/getCurrentWorkspace",
      // getIsCreateBusiness: "profileSettings/getIsCreateBusiness",
      getIsActiveTab: "profileSettings/getIsActiveTab",
      getUserRole: "auth/getUserRole",
      getCurrentWorkspace: "auth/getCurrentWorkspace",
      getEmail: "auth/getEmail",
    }),
    title() {
      return this.$route.name;
    },
    useEmail() {
      return this.$store.getters["auth/getEmail"];
    },
    subPage() {
      return this.$route.meta.subPage;
    },
    parentPage() {
      return this.$route.meta.parentPage;
    },
    toolTipTitle(){
      return this.$route.meta.tooltipTitle
    },
    videoTitle(){
      return this.$route.meta.videoTitle
    },
    worksPaceName() {
      return this.$store.state.auth.currentWorkspace.name;
    },
  },

  // watch: {
  //   isShowSideMenu(val) {
  //     console.log("isShowSideMenu", val);
  //   },
  // },
  async mounted() {
    // console.log("current ws", this.getCurrentWorkspace);
    await this.initCurrentWS();
    await this.initFn();
    this.allowedModules = JSON.parse(this.getModulePermissions())[0].modules;
    this.activePackage = this.getActivePackage().name;
  },
  methods: {
    ...mapGetters({
      getModulePermissions: "auth/getModulePermissions",
      getActivePackage: "auth/getActivePackage",
    }),
    async initFn() {
      let { data } = await GetUserProfile();
      console.log("top bar user data", data);
      this.userProfile = data;
      if (this.userProfile === undefined) {
        this.$router.push("/login");
      }
      if (
        this.userProfile !== undefined &&
        this.userProfile.email_confirmed === false
      ) {
        this.$router.push("/login");
      }
      this.$store.dispatch("auth/setUserRole", data.member_of[0]);
    },
    async initCurrentWS() {
      try {
        this.isBusinessLoading = true;
        let { data, status } = await GetCurrentWorkspace();
        if (status === 200) {
          this.currentBusiness = data;
          console.log("currentBusiness", this.currentBusiness);
          if (!this.currentBusiness) {
            this.$router.push("/login");
          }
        }
        this.isBusinessLoading = false;
      } catch (error) {
        if (error.response.status === 403) {
          this.$router.push("/login");
          return;
        }
        this.isBusinessLoading = false;
      }
    },
    ...mapActions({ logOut: "auth/logOut" }),
    ...mapActions({ workspaceLogin: "auth/workspaceLogin" }),
    showSideMenu() {
      this.$store.dispatch("general/setIsSideMenuExpanded", true);
    },
    settingsFn() {
      // location.replace("/profile");

      if (
        this.allowedModules.includes("Profile") ||
        this.allowedModules.includes("All")
      ) {
        this.$router.push("/profile");
        this.$store.dispatch("profileSettings/setIsActiveTab", false);
        this.$store.dispatch("profileSettings/setIsCreateBusiness", false);
        this.$store.dispatch("profileSettings/setIsActiveBusinessTab", false);
      }
    },
    businessCreateFn() {
      if (this.activePackage === "Figa Green") {
        return;
      }
      // location.replace("/profile");
      this.$router.push("/profile");
      // this.$router.push("/profile/edit/" + id);
      if (this.getIsActiveTab == false)
        this.$store.dispatch("profileSettings/setIsActiveBusinessTab", true);
      this.$store.dispatch("profileSettings/setIsCreateBusiness", false);
      this.$store.dispatch("profileSettings/setIsActiveTab", false);
    },
    logoutFn() {
      localStorage.clear();
      this.logOut();
      location.replace("/login");
    },
    firstLetterFn(text) {
      var initials = text.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();
      return initials;
    },
    switchWorkspaceFn(id) {
      // change workspace
      console.log("change workspace", id);
      this.workspaceLogin(id);
    },

  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top-navbar {
  position: fixed;
  width: 100%;
  max-width: calc(100% - 200px);
  height: 60px;
  z-index: 5;
  top: 0;
  right: 0;
  background-color: var(--white);
  transition: max-width 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
    background-color 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

.top-navbar .display-text {
  font-size: 1.3rem;
}

.top-navbar .state-text {
  font-size: 0.9rem;
}

.top-navbar .terms-text {
  font-size: 0.7rem;
}
.supportCenter-text {
  font-weight: 500;
  font-size: 14px;
  
 
}
.no-fill-icon {
  fill: darkgray !important;
  margin-left: 10px;
}
.custom-tooltip-content{
  font-weight: 500;
  font-size: 16px;
 padding-top: 5px;
 padding-bottom: 5px;
 display: flex;
  align-items: flex-start;
  white-space: nowrap; /* Prevent text from wrapping */
  
    text-overflow: ellipsis; /* Display ellipsis for overflow text */

}
.tooltip-link{
  
  text-decoration: none; 
}
.filter-dropdown {
  height: 40px;
  min-width: 180px;
}

.filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.user-icon-button >>> .user-icon-menu {
  min-width: 420px;
}

.filter-dropdown >>> .filter-dropdown-menu button.dropdown-item.active,
.filter-dropdown >>> .filter-dropdown-menu button.dropdown-item:active,
.user-icon-button >>> .user-icon-menu button.dropdown-item.active,
.user-icon-button >>> .user-icon-menu button.dropdown-item:active {
  background-color: var(--light);
}

.workspace-btn {
  color: var(--dark);
}

.workspace-btn:hover {
  color: var(--main-green);
  text-decoration: none;
}

.user-icon-button >>> .user-icon-pill {
  cursor: pointer;
  border: 1px solid transparent;
  transition: border 150ms ease-in, shadow 150ms ease-in;
}

.user-icon-button:hover >>> .user-icon-pill {
  border: 1px solid var(--main-green);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}

.user-icon-button >>> .user-icon-pill span.text-small {
  font-size: 0.85rem;
  max-width: 120px;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .filter-dropdown {
    min-width: auto;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .top-navbar .display-text {
  font-size: 20px;
}
  .top-navbar,
  .top-navbar.collapsed {
    left: 0;
    width: 100%;
    max-width: 100vw;
    min-width: 100%;
  }

  .user-icon-button >>> .user-icon-menu {
    min-width: 240px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .top-navbar .display-text {
    font-weight: 500;
    font-size: 20px;
  }

  .user-icon-button >>> .user-icon-pill .user-avatar {
    font-size: 0.7rem;
  }

  .filter-dropdown {
    height: 35px;
    min-width: auto;
  }

  .user-icon-button >>> .user-icon-menu {
    min-width: 240px;
  }
}
</style>
<style >
.SupportTooltip .tooltip-inner {
 
    background-color: white;
   border:  1px solid var(--dark);
 max-width: 100% !important;
   
}
</style>